<template>
      <ion-item
        :router-link="`/content/headlines/${headline.id}`"
        >
            <ion-thumbnail id="headlineImageThumbnail" slot="start">
                <ion-img :src="headline.image" :alt="headline.title"></ion-img>
            </ion-thumbnail>
            <ion-label>
                <h2 v-if="headline.title.length > 0">{{ headline.title }}</h2>
                <h2 v-if="headline.title.length == 0" v-html="headline.description"></h2>
                <h6 v-if="headline.is_pinned == 0">{{ headline.headlineDate }}</h6>
                <p v-if="(headline.location == null || headline.location.length == 0)">
                    {{ this.locationText(headline.location_id) }}
                </p>
                <p v-else>{{ headline.location }}</p>
            </ion-label>
            <ion-badge v-if="isToday(headline.headline2Date)" color="warning"><ion-icon :icon="hourglassOutline" :ios="hourglassOutline" :md="hourglassOutline" />Today </ion-badge>
            <ion-badge color="secondary"><ion-icon :icon="thumbsUpSharp" :ios="thumbsUpSharp" :md="thumbsUpSharp" /> {{ headline.likedby }} </ion-badge>
        </ion-item>
</template>

<script>
import { IonItem, IonThumbnail, IonImg, IonLabel, IonIcon, IonBadge } from '@ionic/vue'
import { thumbsUpSharp, hourglassOutline } from 'ionicons/icons';
import sharedMixins from '../../shared/shared-mixins';
import { format } from 'date-fns';

export default {
    props: ["headline"],
    mixins: [ sharedMixins ],
    components: {
        IonItem,
        IonThumbnail,
        IonImg,
        IonLabel,
        IonIcon,
        IonBadge,
    },
    setup() {
        return {
        thumbsUpSharp,
        hourglassOutline
        }
    },
    methods: {
        locationText(id) {
            return this.getMixedLocation(id);
        },
        isToday(date) {
            const today = format(new Date(), 'yyyy-MM-dd');
            if (date == today)
                return true;
            return false;
        }
    },
};
</script>
