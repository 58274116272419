<template>
      <ion-item
        :router-link="`/content/businesses/${business.id}`"
        >
            <ion-thumbnail id="businessImageThumbnail" slot="start">
                <ion-img :src="business.image" :alt="business.businessName"></ion-img>
            </ion-thumbnail>
            <ion-label>
                <h2 v-if="business.businessName.length > 0">{{ business.businessName }}</h2>
                <h2 v-if="business.businessName.length == 0" v-html="business.description"></h2>
                <h6 v-if="business.is_pinned == 0">{{ business.headlineDate }}</h6>
                <p>{{ business.location }}</p>
            </ion-label>
        </ion-item>
</template>

<script>
import { IonItem, IonThumbnail, IonImg, IonLabel } from '@ionic/vue'

export default {
    props: ["business"],
    components: {
        IonItem,
        IonThumbnail,
        IonImg,
        IonLabel,
    },
};
</script>
