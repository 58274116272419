<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="side-menu-list">
            <ion-list-header>
              <ion-thumbnail>
                <ion-img src="/assets/icon/logo.png"></ion-img>
              </ion-thumbnail>
            </ion-list-header>
            <ion-note>
              <ion-label><h3>BALAAGH HEADLINES</h3></ion-label>
              <ion-label><a href="mailto: info@balaagh.com" class="paneEmailLink">info@balaagh.com</a></ion-label>
            </ion-note>
            
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" :class="{ selected: selectedIndex === i }" style="cursor: pointer;">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-list id="labels-list">
            <ion-list-header>Contact Us</ion-list-header>
            <ion-menu-toggle auto-hide="false" >
              <ion-item href="https://github.com/apps-alathar/Balaagh/issues" target="_blank" lines="none" detail="false" rel="noopener noreferrer" style="cursor: pointer;">
                <ion-icon :ios="logoGithub" :md="logoGithub" ></ion-icon> 
                <ion-label>&nbsp; GitHub</ion-label>
              </ion-item>
              <ion-item href="https://twitter.com/atharaziz" target="_blank" rel="noopener noreferrer" lines="none" detail="false" style="cursor: pointer;">
                <ion-icon :ios="logoTwitter" :md="logoTwitter" ></ion-icon> 
                <ion-label>&nbsp; Twitter</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <a v-if="!isAndroid"
              href="https://play.google.com/store/apps/details?id=com.balaagh.headlines&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"><img style="width: 240px; height: auto;"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          /></a>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script>
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonNote, IonImg, IonThumbnail, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bagHandleOutline, bagHandleSharp, businessOutline, businessSharp, micCircleOutline, micCircleSharp, 
  hammerOutline, hammerSharp, informationCircleOutline, informationCircleSharp, calendarOutline, calendarSharp, addOutline, addSharp } from 'ionicons/icons';
import { logoGithub, logoTwitter } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet, 
    IonSplitPane,
    IonImg,
    IonThumbnail,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Headlines',
        url: '/content/headlines',
        iosIcon: calendarOutline,
        mdIcon: calendarSharp
      },
      {
        title: 'Businesses',
        url: '/content/businesses',
        iosIcon: businessOutline,
        mdIcon: businessSharp
      },
      {
        title: 'About',
        url: '/content/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp
      }
    ];

    const path = window.location.pathname.split('content/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages,
      archiveOutline, 
      archiveSharp, 
      bagHandleOutline, 
      bagHandleSharp, 
      businessOutline, 
      businessSharp, 
      micCircleOutline, 
      micCircleSharp, 
      logoGithub,
      logoTwitter, 
      hammerOutline, 
      hammerSharp, 
      informationCircleOutline, 
      informationCircleSharp, 
      calendarOutline, 
      calendarSharp,
      addOutline,
      addSharp,
      isSelected: (url) => url === route.path ? 'selected' : '',
      isAndroid: isPlatform("android")
    }
  }
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#side-menu-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#side-menu-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.paneEmailLink {
  color: var(--ion-color-primary);
}

/* Custom Dark Theme */
@media (prefers-color-scheme: dark) {

  ion-item.selected {
    --color: rgba(var(--ion-color-light-rgb), 0.9);
  }

  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-secondary-rgb), 0.14);
  }

  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-light);
  }

  ion-menu.ios ion-item.selected ion-icon {
    color: var(--ion-color-light);
  }

  .paneEmailLink {
    color: var(--ion-color-light);
  }
}
</style>
