<template>
    <ion-item-divider color="primary">
        <ion-label>
        Upcoming Events
        </ion-label>
    </ion-item-divider>
    <ion-list>
        <headline-list-item
            v-for="headline in upcomingHeadlines"
            :key="headline.id"
            :headline="headline"
        >
        </headline-list-item>
    </ion-list>
    <ion-item-divider color="secondary">
        <ion-label>
        Special / Long-Term Events
        </ion-label>
    </ion-item-divider>
    <ion-list>
        <headline-list-item
            v-for="headline in pinnedHeadlines"
            :key="headline.id"
            :headline="headline"
        >
        </headline-list-item>
    </ion-list>
    <ion-item-divider color="danger">
        <ion-label>
        Past Events
        </ion-label>
    </ion-item-divider>
    <ion-list>
        <headline-list-item
            v-for="headline in pastHeadlines"
            :key="headline.id"
            :headline="headline"
        >
        </headline-list-item>
    </ion-list>
</template>
<script>
import { IonList, IonItemDivider, IonLabel } from '@ionic/vue'
import HeadlineListItem from './HeadlineListItem.vue';
import { format } from 'date-fns';

export default {
    props: ["headlines"],
    components: {
        IonList,
        HeadlineListItem,
        IonItemDivider,
        IonLabel,
    },
    computed: {
      upcomingHeadlines() {
          const today = format(new Date(), 'yyyy-MM-dd');
          return this.headlines.filter(headline => (headline.headline2Date >= today) && headline.is_pinned != 1);
      },
      pinnedHeadlines() {
          return this.headlines.filter(headline => headline.is_pinned == 1).reverse();
      },
      pastHeadlines() {
          const today = format(new Date(), 'yyyy-MM-dd');
          return this.headlines.filter(headline => (headline.headline2Date < today) && headline.is_pinned != 1).reverse();
      },
  }
}
</script>