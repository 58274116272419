import { createRouter, createWebHistory } from '@ionic/vue-router';
import HeadlinesPage from '../pages/HeadlinesPage.vue';
import AboutPage from '../pages/AboutPage.vue';
import BusinessesPage from '../pages/BusinessesPage.vue';

const routes = [
  {
    path: '',
    redirect: '/content/headlines'
  },
  {
    path: '/content/headlines',
    component: HeadlinesPage,
  },
  {
    path: '/content/about',
    component: AboutPage,
  },
  {
    path: '/content/businesses',
    component: BusinessesPage,
  },
  {
    path: '/content/headlines/:id',
    component: () => import ('../pages/HeadlineDetailsPage.vue')
  },
  {
    path: '/content/headlines/add',
    component: () => import ('../pages/AddHeadlinePage.vue')
  },
  {
    path: '/content/businesses/:id',
    component: () => import ('../pages/BusinessDetailsPage.vue')
  },
  {
    path: '/content/businesses/add',
    component: () => import ('../pages/AddBusinessPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
