import { createStore } from 'vuex';
import { dataService } from '../shared/data-service';
import { GET_HEADLINES, ADD_HEADLINE, LIKE_HEADLINE, GET_COUNTERS, GET_BUSINESSES } from './mutation-types';

const state = () => ({
  headlines: [],
  businesses: [],
  counters: [],
});

const store = createStore({
    state,
    mutations: {
      [ADD_HEADLINE](state, headline) {
        console.log(headline);
        state.headlines.unshift(headline);
      },
      [GET_HEADLINES](state, headlines) {
        state.headlines = headlines;
      },
      [LIKE_HEADLINE](state, id) {
        const index = state.headlines.findIndex((h) => h.id === id);
        if (index !== -1) {
          var liked = state.headlines[index].likedby;
          state.headlines[index].likedby = (Number(liked) + 1).toString();
        }
      },
      [GET_BUSINESSES](state, businessesData) {
        state.businesses = businessesData;
      },
      [GET_COUNTERS](state, countersData) {
        state.counters = countersData;
      }
    },
    actions: {
      async addHeadlineAction({ commit }, headlineData) {
        const addedHeadline = await dataService.addHeadline(headlineData);
        if (addedHeadline != null) { commit(ADD_HEADLINE, addedHeadline); }
      },
      async getHeadlinesAction({ commit }) {
        const headlines = await dataService.getHeadlines();
        commit(GET_HEADLINES, headlines);
      },
      async likeHeadlineAction({ commit }, id) {
        await dataService.likeHeadline(id);
        commit(LIKE_HEADLINE, id);
      },
      async getBusinessesAction({ commit }) {
        const businesses = await dataService.getBusinesses();
        commit(GET_BUSINESSES, businesses);
      },
      async getCountersAction({ commit }) {
        const counters = await dataService.getCounters();
        commit(GET_COUNTERS, counters);
      }
    },
    getters: {
        headlines(state) {
            return state.headlines;
        },
        headline(state) {
            return (headlineId) => {
                return state.headlines.find(headline => headline.id === headlineId);
            }
        },
        businesses(state) {
            return state.businesses;
        },
        business(state) {
            return (businessId) => {
                return state.businesses.find(business => business.id === businessId);
            }
        },
        counters(state) {
            return (counterCode) => {
                return state.counters.find(counter => counter.counter_code === counterCode);
            }
        }
    }
});

export default store;