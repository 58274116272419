<template>
  <base-layout page-title="Balaagh Top Stories">
    <template v-slot:actions-end>
        <ion-button router-link="/content/headlines/add">
          <ion-icon slot="icon-only" :icon="add"></ion-icon>
        </ion-button>
    </template>
    <headlines-list :headlines="headlines"></headlines-list>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { IonButton, IonIcon } from '@ionic/vue';
import { add} from 'ionicons/icons';

import HeadlinesList from '../components/headlines/HeadlinesList.vue';

export default {
  components: {
      HeadlinesList,
      IonButton,
      IonIcon,
  },
  data() {
    return {
        add
    };
  },
  computed: {
      headlines() {
          return this.$store.getters.headlines;
      }
  },
  async created() {
    await this.loadHeadlines();
  },
  methods: {
    ...mapActions(['getHeadlinesAction']),
    async loadHeadlines() {
      if (this.$store._state.data.headlines.length == 0) {
        await this.getHeadlinesAction();
      }
    },
  },
};
</script>
