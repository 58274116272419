<template>
  <base-layout page-title="Businesses Around Town">
    <template v-slot:actions-end>
        <ion-button router-link="/content/businesses/add">
          <ion-icon slot="icon-only" :icon="add"></ion-icon>
        </ion-button>
    </template>
    <businesses-list :businesses="businesses"></businesses-list>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { IonButton, IonIcon } from '@ionic/vue';
import { add} from 'ionicons/icons';

import BusinessesList from '../components/businesses/BusinessesList.vue';

export default {
  components: {
      BusinessesList,
      IonButton,
      IonIcon,
  },
  data() {
    return {
        add
    };
  },
  computed: {
      businesses() {
          return this.$store.getters.businesses;
      }
  },
  async created() {
    await this.loadBusinesses();
  },
  methods: {
    ...mapActions(['getBusinessesAction']),
    async loadBusinesses() {
      await this.getBusinessesAction();
    },
  },
};
</script>
