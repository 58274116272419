export default {
  data() {
    return {
      mixLocations: [],
      mixSources: [],
      mixSourceImages: [],
    };
  },
  created() {
    this.mixLocations = this.loadLocations();
    this.mixSources = this.loadSources();
    this.mixSourceImages = this.loadSourceImages();
  },
  methods: {
    loadLocations() {
      return [
        { text: 'Other (select or specify below)', value: 0 },
        { text: 'Al-Huda School', value: 1 },
        { text: 'Masjid Al-Nur', value: 3 },
        { text: 'Masjid Al-Sabereen', value: 4 },
        { text: 'Masjid ICP', value: 5 },
        { text: 'Indoor Swim Group', value: 7 },
        { text: 'Islamic Society of Greater Harrisburg', value: 8 },
        { text: 'Elks Lodge in Camp Hill', value: 9 },
        { text: 'Red Land High School in Lewisberry', value: 10 },
        { text: 'Central PA Committee of Masajid (CPCOM)', value: 11 },
        { text: 'Online', value: 12 },
      ];
    },
    loadSources() {
      return [
        { text: 'Other', value: 0 },
        { text: 'Al-Huda School', value: 1 },
        { text: 'Daawah In Action', value: 2 },
        { text: 'Masjid Al-Nur', value: 3 },
        { text: 'Masjid Al-Sabereen', value: 4 },
        { text: 'Masjid ICP', value: 5 },
        { text: 'ICNA Harrisburg', value: 6 },
        { text: 'Indoor Swim Group', value: 7 },
        { text: 'Islamic Society of Greater Harrisburg', value: 8 },
        { text: 'eMgage Action Team', value: 9 },
        { text: 'Balaagh Team', value: 10 },
        { text: 'Central PA Committee of Masajid (CPCOM)', value: 11 },
        { text: 'Online/Internet', value: 12 },
      ];
    },
    loadSourceImages() {
      return [
        { text: 'no_image_128.png', value: 0 },
        { text: 'source_alhuda.png', value: 1 },
        { text: 'no_image_128.png', value: 2 },
        { text: 'source_alnur.png', value: 3 },
        { text: 'source_alsabereen.png', value: 4 },
        { text: 'source_icp.png', value: 5 },
        { text: 'source_icna.png', value: 6 },
        { text: 'source_swimgroup.png', value: 7 },
        { text: 'source_isgh.png', value: 8 },
        { text: 'no_image_128.png', value: 9 },
        { text: 'source_balaagh.png', value: 10 },
        { text: 'source_cpcom', value: 11 },
        { text: 'source_internet.svg', value: 12 },
      ];
    },
    getMixedLocation(id) {
      var locations = this.loadLocations();
      for (var index = 0; index < locations.length; index++) {
        if (locations[index]['value'] == id) {
          return locations[index]['text'];
        }
      }
      //console.log(this.loadLocations.filter(f => f.value === id));
      //return this.loadLocations.filter(f => f.value === id);
    }
  },
};
