<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button color="primary"></ion-menu-button>
                </ion-buttons>
                <ion-title>{{ pageTitle }}</ion-title>
                <ion-buttons slot="end">
                    <slot name="actions-end"></slot>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-back-button color="primary" :default-href="pageDefaultBackLink"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <slot />
            </ion-list>
        </ion-content>
    </ion-page>

</template>
<script>
import {
  IonPage,
  IonMenuButton,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonContent,
  IonList,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";

export default {
    props: ["pageTitle", "pageDefaultBackLink"],
    components: {
        IonPage,
        IonMenuButton,
        IonToolbar,
        IonHeader, 
        IonTitle, 
        IonContent,
        IonList,
        IonBackButton,
        IonButtons,
    }
}

</script>
