<template>
    <ion-item-divider color="primary">
        <ion-label>
            Sponsored
        </ion-label>
    </ion-item-divider>
    <ion-list>
        <business-list-item
            v-for="business in sponsoredBusinesses"
            :key="business.id"
            :business="business"
        >
        </business-list-item>
    </ion-list>
    <ion-item-divider color="secondary">
        <ion-label>
            Businesses
        </ion-label>
    </ion-item-divider>
    <ion-list>
        <business-list-item
            v-for="business in normalBusinesses"
            :key="business.id"
            :business="business"
        >
        </business-list-item>
    </ion-list>
</template>
<script>
import { IonList, IonItemDivider, IonLabel } from '@ionic/vue'
import BusinessListItem from './BusinessListItem.vue';


export default {
    props: ["businesses"],
    components: {
        IonList,
        BusinessListItem,
        IonItemDivider,
        IonLabel,
    },
    computed: {
      sponsoredBusinesses() {
          return this.businesses.filter(business => business.is_sponsored == 1);
      },
      normalBusinesses() {
          return this.businesses.filter(business => business.is_sponsored != 1);
      },
  }
}
</script>