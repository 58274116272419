<template>
  <base-layout page-title="About Balaagh Headlines">
    <ion-list class="ion-padding">
      <ion-item v-for="benefit in benefits" :key="benefit.id">
        <ion-label class="ion-text-wrap">{{ benefit.message }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          Our visitors since 9/1/2020:
          <strong> {{ visitors }} </strong>. Thank you!
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          Balaagh Headlines by Al Athar Apps and <a href="http://www.aazitech.com/" target="_blank" rel="noopener noreferrer nofollow">AAzitech Solutions LLC</a> - 
          <a href="https://twitter.com/atharaziz" target="_blank"
            >Athar Aziz</a
          >
          and Adil Aziz
        </ion-label>
        <ion-icon
          :ios="logoTwitter"
          :md="logoTwitter"
          slot="end"
          name="logo-twitter"
        ></ion-icon>
      </ion-item>
      <ion-item>
        <ion-label>Version 5 (1.4) 2022.03.20
        </ion-label>
      </ion-item>
    </ion-list>
    <a v-if="!isAndroid"
      href="https://play.google.com/store/apps/details?id=com.balaagh.headlines&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      target="_blank"><img style="width: 240px; height: auto; text-align: right;"
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
    /></a>
    <ion-list class="ion-padding">
      <ion-item-divider color="primary">
        <ion-label> Want to help? </ion-label>
      </ion-item-divider>
      <ion-item v-for="help in helps" :key="help.id">
        <ion-label class="ion-text-wrap">{{ help.message }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          Follow us to a Masjid near you or volunteer at an event at
          <a
            href="https://github.com/apps-alathar/Balaagh/issues"
            target="_blank"
          >
            GitHub
          </a>
        </ion-label>
        <ion-icon
          :ios="logoGithub"
          :md="logoGithub"
          slot="end"
          name="logo-github"
        ></ion-icon>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          This project is still under development. For more information please
          contact

          <a href="mailto:athar@aazitech.com">
            Athar Aziz
          </a>
        </ion-label>
        <ion-icon :ios="mail" :md="mail" slot="end" name="logo-mail"></ion-icon>
      </ion-item>
    </ion-list>
  </base-layout>
</template>

<script>
import { mapActions } from "vuex";
import { IonList, IonItem, IonItemDivider, IonIcon, IonLabel, isPlatform } from "@ionic/vue";
import { logoGithub, logoTwitter, mail } from "ionicons/icons";

export default {
  name: "AboutPage",
  components: { IonList, IonItem, IonItemDivider, IonIcon, IonLabel },
  data() {
    return {
      benefits: [
        { id: "1", message: "Absolutely Free!" },
        {
          id: "2",
          message: "We do not track you!",
        },
        {
          id: "3",
          message: "Your top stories organized in one location",
        },
        {
          id: "4",
          message:
            "No account or registration required to keep up with the happenings in and around Central PA",
        },
        {
          id: "5",
          message:
            "The One-stop for all Muslim events in and around Central PA",
        },
        {
          id: "6",
          message: "Anyone can post an upcoming event (* Access Key required)",
        },
      ],
      helps: [
        {
          id: "1",
          message:
            "You can help us post the events, services, news, local businesses etc. just ask us for the Access Key.",
        },
        {
          id: "2",
          message:
            "A project like this takes a lot of hardwork and dedication. We request your support and patience while we develop this tool for the entire community.",
        },
        {
          id: "3",
          message:
            "We ask that you make DUA (i.e., say a prayer) for all the developers that have spent countless number of hours making this project a success.",
        },
      ],
      visitors: 0,
      isAndroid: isPlatform("android")
    };
  },
  async created() {
    await this.loadCounters();
  },
  methods: {
    ...mapActions(["getCountersAction"]),
    async loadCounters() {
      await this.getCountersAction();
      this.visitors = this.$store.getters.counters("VISITORS").counter;
    },
  },
  setup() {
    return {
      logoGithub,
      logoTwitter,
      mail,
    };
  },
};
</script>

<style scoped>
.content-title-group div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ion-text-wrap a {
  color: blue;
}
@media (prefers-color-scheme: dark) {

  .ion-text-wrap a {
    color: lightblue;
  }
  
}
</style>
