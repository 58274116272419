import * as axios from 'axios';
import { parseISO, format } from 'date-fns';

const takeAway = false;
const API = "https://api.balaagh.com/api";
const userCreds = { user_id: 1, token: 'a20f3362c6c77b5d32ea1d692c9a33e74cf1deb4e364d8e78a9c94f226daa198' }
const userToken = 'a20f3362c6c77b5d32ea1d692c9a33e74cf1deb4e364d8e78a9c94f226daa198'

const getHeadlines = async function () {
  try {
    var data = {};
    if (takeAway) {
      const response = await axios.get(`/api/events-db.json`);
      data = parseList(response, 200);
      data = data.balaagh;
    } else {
      const response = await axios.post(
        `${API}/events`,
        userCreds,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );
      data = parseList(response);
    }
    return formatHeadlines(data.feedData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const addHeadline = async function (headline) {
  try {

    const newHeadline = {
      user_id: 1,
      token: userToken,
      feed: headline.description,
      title: headline.title,
      location_id: headline.location_id,
      location: headline.location,
      event_dt: headline.event_dt,
      eventend_dt: headline.eventend_dt,
      source_id: headline.source_id,
      flyer: headline.flyer,
      accesskey: headline.accesskey,
      is_pinned: headline.is_pinned
    };

    await axios.post(`${API}/event`, newHeadline, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(function (response) {
      var addedHeadline = parseList(response);
      const formatted = formatHeadlines(addedHeadline.feedData);
      return formatted;
    })
    .catch(function () {
      console.log('Adding failed!!');
      return null;
    });
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

const likeHeadline = async function (id) {
  try {
    if (takeAway) {
      const response = await axios.get(`/api/events-db.json`);
      parseItem(response, 200);
    } else {
      const response = await axios.post(
        `${API}/likeevent`,
        { user_id: 1, token: userToken, feed_id: id },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );
      parseList(response);
    }
    return id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getBusinesses = async function () {
  try {
    var data = {};
    if (takeAway) {
      const response = await axios.get(`/api/events-db.json`);
      data = parseList(response, 200);
      data = data.balaagh;
    } else {
      const response = await axios.post(
        `${API}/businesses`,
        userCreds,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );
      data = parseList(response);
    }
    const businesses = data.businessData.map((h) => {
      h.id = `${h.business_id}`;
      h.description = `${h.details}`;
      if (h.flyer_url != null && h.flyer_url.length > 0)
        h.image = `https://flyers.balaagh.com/src/${h.flyer_url}`;
      else h.image = null;
      h.businessName = `${h.business_name}`;
      h.headlineDate = format(parseISO(h.posted_dt), 'EEEE, MMMM d yyyy');
      h.headline2Date = format(parseISO(h.posted_dt), 'yyyy-MM-dd');
      return h;
    });
    return businesses;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getCounters = async function () {
  try {
    const response = await axios.post(
      `${API}/counters`,
      userCreds,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
    let data = parseList(response);
    const counters = data.counterData.map((c) => {
      c.id = `${c.counter_id}`;
      c.description = `${c.description}`;
      c.counter = `${c.counter}`;
      if (c.counter == 'null') {
        c.counter = 0;
      }
      return c;
    });
    return counters;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const formatHeadlines = function (unformatted) {
  try {
    const headlines = unformatted.map((h) => {
      h.id = `${h.feed_id}`;
      h.description = `${h.feed}`;
      if (h.title == 'null') { h.title = ''; }
      if (h.flyer_url != null && h.flyer_url.length > 0)
        h.image = `https://flyers.balaagh.com/src/${h.flyer_url}`;
      else h.image = null;
      h.headlineDate = format(parseISO(h.event_dt), 'EEEE, MMMM d yyyy');
      h.headline2Date = format(parseISO(h.event_dt), 'yyyy-MM-dd');
      return h;
    });
    return headlines;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const uploadFile = async function (file) {
  try {
    let formData = new FormData();
    formData.append('file', file);
    var filename = '';

    await axios
      .post('https://flyers.balaagh.com/api.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (data) {
        filename = data.data;
      })
      .catch(function () {
        console.log('Flyer upload failed!!');
        return null;
      });
    
    return filename;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

const parseList = (response) => {
    if (response.status !== 200 && response.status !== 201) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== 'object') {
      list = [];
    }
    return list;
};

export const parseItem = (response, code) => {
  if (response.status !== code) throw Error(response.message);
  let item = response.data;
  if (typeof item !== 'object') {
    item = undefined;
  }
  return item;
};

export const dataService = {
    getHeadlines,
    addHeadline,
    likeHeadline,
    getBusinesses,
    getCounters,
    uploadFile
}